<template>
	<recess-card variant="variant1" class="px-5 my-3">
		<!-- Subtitle -->
		<div class="row">
			<div class="col-12">
				<subtitle-text
					:subtitle="PROVIDER.Portfolio.CourseEdit.InvestmentTab.Title"
					qa-class="qa-edit-invoice-item"
				/>
				<static-alert
					type="generic"
					:message="PROVIDER.Portfolio.CourseEdit.InvestmentTab.ApiCourseAlertMessage"
					:is-visible="isApi"
				/>
			</div>
		</div>

		<div class="row">
			<!-- Shared invoice item fields -->
			<div class="col-12">
				<subsidies
					:subsidy-value="planningAndInvoicesData.subsidies"
					@triggerSubsidiesValue="setNewSubsidies"
				/>
			</div>
		</div>

		<div class="row mb-5">
			<!-- Shared invoice item fields -->
			<div class="col-12">
				<invoice-item
					:planning-and-invoices-data="planningAndInvoicesData"
					:countriesProp="countriesProp"
					@emitInvoiceChanges="updateInvoices"
					@setVatValue="setVatValue"
					@setCountries="setCountries"
					ref="invoiceItems"
				/>
			</div>
		</div>

		<div class="row">
			<div class="col-12">
				<h3>
					{{ PROVIDER.Portfolio.CourseEdit.InvestmentTab.StartmomentInvestmentTitle }}
				</h3>
			</div>
		</div>

		<div class="row">
			<div class="col-12">
				<h5 class="mb-4">
					{{ PROVIDER.Portfolio.CourseEdit.InvestmentTab.StartmomentSubTitle }}
				</h5>

				<!-- Start moment specific pricing accordion -->

				<div class="row"
					v-if="startMoments && startMoments.length > 0"
				>
					<!-- Start moment list's headers -->
					<div class="col-12">
						<div class="row mr-0">
							<div class="col-4 u-fw-semi-bold qa-start-moment-pricing-accordion-header-0">
								Startmoment kenmerk / ID
							</div>
							<div
								v-for="(header, index) in headers"
								:key="`header-${index}`"
								:class="`col-2 u-fw-semi-bold qa-start-moment-pricing-accordion-header-${index + 1}`"
							>
								{{ header.title }}
							</div>
						</div>
					</div>

					<!-- Start moment accordion -->
					<div
						v-for="(startMoment, startMomentIndex) in startMoments"
						:key="`startMomentPricingAccordion-${startMomentIndex}`"
						class="col-12"
					>
						<recess-accordion
							variant="variant-3"
							:class="`qa-start-moment-accordion-specific-pricing-data-${startMomentIndex}`"
							:active="startMoment.isActive ? true : false"
							ref="startmoments"
							@toggled="toggleAccordionHandler(startMomentIndex)"
						>
							<template slot="accordion-title">
								<div class="row justify-content-start align-items-center">
									<div class="col-4 u-text-bold qa-start-moment-id-column">
										{{ startMoment.externalId || '-' }}
									</div>
									<div class="col-2 qa-start-moment-date-column">
										{{ getStartmomentDisplayDate(startMoment) }}
									</div>
									<div class="col-2 qa-start-moment-type-column">
										{{ startMoment.typeDisplayValue || '-' }}
									</div>
									<div class="col-2 qa-start-moment-location-column">
										{{ startMoment.city || '-' }}
									</div>
									<div class="col-2 qa-start-moment-specific-pricing-column">
										{{ showTotalPrice(startMomentIndex) }}
									</div>
								</div>
							</template>

							<div slot="accordion-content">
								<div class="col-12 mt-3 u-line-height-unset">
									<h3 class="mb-4">Investering voor dit startmoment</h3>
									<recess-button
										v-if="!hasSpecificPricing(startMomentIndex)"
										title="Start met standaardwaarden"
										variant="tertiary"
										:class="`mb-4 qa-copy-standard-pricing-button-${startMomentIndex}`"
										@click.native.prevent="setStandardPricingVisibility(startMomentIndex)"
									/>
									<invoice-item
										ref="startMomentsInvoiceItems"
										always-delete
										:planning-and-invoices-data="planningAndInvoicesData"
										:start-moment-index="startMomentIndex"
										:countriesProp="countriesProp"
										:class="`qa-start-moment-specific-pricing-${startMomentIndex}`"
										@setVatValue="setVatValue"
										@emitInvoiceChanges="updateInvoices"
										@setCountries="setCountries"
									/>
								</div>
							</div>
						</recess-accordion>
					</div>
				</div>

				<!-- If no future/past start moments exist, show message -->
				<p v-else>{{ PROVIDER.Portfolio.CourseCreate.Form.Startmoments.NoStartMomentAvailable }}</p>

			</div>
		</div>

		<div class="row">
			<div class="col-12 mt-3 d-flex justify-content-end">
				 <recess-button
                    v-if="!isOnCourseEditPage"
                    variant="secondary"
                    :title="BUTTON_TEXT.previousTab"
                    class="qa-previous-tab-button mr-3"
                    @click.prevent.native="handleClickOnTab(null, 'previous-tab')"
                />

                <recess-button
                    variant="secondary"
                    :title="!isOnCourseEditPage ? BUTTON_TEXT.nextTab : BUTTON_TEXT.save"
                    class="qa-edit-course-submit-button"
                    @click.prevent.native="tabButtonAction()"
                />
			</div>
		</div>
	</recess-card>
</template>

<script>
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'

import { BUTTON_TEXT, API_CALL_URL_PATHS } from '@/../../shared/constants/constantsGeneral.json'

const SubtitleText = () => import('@/components/atoms/SubtitleText')
const StaticAlert = () => import('@/components/atoms/StaticAlert')
const InvoiceItem = () => import('./SubComponents/InvoiceItem.vue')
const Subsidies = () => import('@/components/organisms/Portfolio/CourseEdit/Subsidies')

import { months } from '@/../../shared/enums/months.js'
import { emptyInvoiceItem } from './courseEditHelpers'
import tabMixin from '../../../mixins/tabNavigationMixin'
import { toISODateString, formatEventDateTimeToLocal } from '@/utils/dateTimeHelper'
import { setVatPercentageOptionsForSpecificInvoiceItems } from '@/utils/vuexFunctionHelper'
import { getItemById } from '@/../../shared/api/SharedClient'

export default {
	mixins: [tabMixin],
	components: {
		SubtitleText,
		InvoiceItem,
		StaticAlert,
		Subsidies
	},
	props: {
		planningAndInvoicesData: {
			type: Object,
			required: false,
			default: () => ({
				source: null,
				productType: null,
				learningMethod: {
					type: null,
					format: null,
					contactSessions: null,
					costs: {
						details: [
							{
								invoiceItems: [{
									quantity: 1,
									priceBeforeTax: null,
									vatAmount: 0,
									vatCountry: 'NL',
									vatPercentage: null,
									vatPercentageOptions: [],
									vatExemptAmount: 0,
									vatExemptAmountVAT: 0,
									costType: null
								}]
							}

						]
					},
					duration: {
						unit: '',
						value: null
					},
					planning: {
						startMoments: []
					},
					studyLoad: {
						value: null,
						unit: null
					},
					language: '',
					languageMaterial: ''
				}
			})
		}
	},
	data() {
		return {
			PROVIDER,
			BUTTON_TEXT,
			currentTabCourseData: {},
			headers: [
				{ key: 'StartDate', title: 'Startmoment' },
				{ key: 'Type', title: 'Type' },
				{ key: 'Location', title: 'Locatie' },
				{ key: '', title: 'Totaalprijs' }
			],
			months,
			startMomentTypes: null,
			countriesProp: []
		}
	},
	computed: {
		isApi() {
			return this.planningAndInvoicesData.source === 'API'
		},
		startMoments() {
			if (this.planningAndInvoicesData && this.planningAndInvoicesData.learningMethod && this.planningAndInvoicesData.learningMethod.planning) {
				return this.planningAndInvoicesData.learningMethod.planning.startMoments
			}
			return []
		}
	},
	deactivated() {
		// called when removed from the DOM into the cache
		// and also when unmounted
		if (!this.startMoments) return

		this.$refs['startmoments']?.forEach(element => {
			if (element.dActive === true) {
				element.dActive = false
			}
		});
	},

	methods: {
		getStartmomentDisplayDate(startmoment) {
			const isoDate = toISODateString(startmoment.startDate)
			const dateFormat = new Date(isoDate)

			const month = this.getStartMomentMonth((dateFormat.getMonth() + 1).toString().padStart(2, '0'))
			return `${(dateFormat.getDate()).toString().padStart(2, '0')} ${month} ${(dateFormat.getFullYear()).toString()}`
		},
		setNewSubsidies(newSubsidies) {
			const setSubsidies = {
				key: 'subsidies',
				value: newSubsidies
			}

			this.setVatValue(setSubsidies)
		},
		updateInvoices(updatedInvoices) {

			if (!this.planningAndInvoicesData.learningMethod) return

			if (updatedInvoices.startMomentIndex === null) {
				this.planningAndInvoicesData.learningMethod.costs.details[0].invoiceItems = updatedInvoices.invoices
			} else {
				this.planningAndInvoicesData.learningMethod.planning.startMoments[updatedInvoices.startMomentIndex].invoiceItems = updatedInvoices.invoices
			}
		},
		getStartMomentMonth(monthInputValue) {
			const formattedMonth = parseInt(monthInputValue, 10)
			const monthEnum = months.find((x) => x.value === `${formattedMonth}`)
			if (!monthEnum) return ''
			return `${monthEnum.displayText}`
		},
		showTotalPrice(index) {
			return this.hasSpecificPricing(index) ? 'Aangepast' : 'Standaard'
		},
		hasSpecificPricing(index) {
			return (
				this.startMoments &&
				this.startMoments[index].invoiceItems &&
				this.startMoments[index].invoiceItems.length > 0 &&
				this.startMoments[index].invoiceItems[0].costType !== null
			)
		},
		async getStartmomentDetails(id, index) {
			try {
				const response = await getItemById(
					`${process.env.VUE_APP_PROVIDER_V2_API_URL}${API_CALL_URL_PATHS.courses}/`,
					this.getCourseId,
					`start-moments/${id}`,
					null,
					false
				)

				if (!response) return
				response.hasDetails = true
				let startmomentDetails = response
				formatEventDateTimeToLocal([startmomentDetails])
				setVatPercentageOptionsForSpecificInvoiceItems([startmomentDetails])

				this.$emit('setStartMoments', { action: 'setStartmomentDetail', index, startmomentDetails })
			} catch (error) {
				console.error('Something went wrong while retrieving start moment details', error)
			}
		},
		async toggleAccordionHandler(startMomentIndex) {
			const startmomentId = this.startMoments[startMomentIndex].id

			if (!this.startMoments[startMomentIndex].hasDetails && startmomentId) {
				await this.getStartmomentDetails(startmomentId, startMomentIndex)
			}

			if (this.$refs['startmoments'][startMomentIndex].$children.length > 0) {
				this.$refs['startmoments'][startMomentIndex].$children.forEach((child) => {
					if (child.$v !== undefined && child.invoiceItems.length > 0) {
						child.$v.$touch()
						if (child.$v.$invalid) {
							this.$refs['startmoments'][startMomentIndex].dActive = true
						}
					}
				})


				if (!this.hasSpecificPricing(startMomentIndex)) {
					if (this.planningAndInvoicesData.learningMethod.planning.startMoments[startMomentIndex].invoiceItems.length === 0) {
						this.$emit('setStartmoments', { action: 'addInvoice', startMomentIndex, value: emptyInvoiceItem })
					}
				}
			}
		},
		setStandardPricingVisibility(startMomentIndex) {
			this.planningAndInvoicesData.learningMethod.planning.startMoments[startMomentIndex].invoiceItems = JSON.parse(JSON.stringify(this.planningAndInvoicesData.learningMethod.costs.details[0].invoiceItems))
			this.$emit('triggerProgressChange', this.planningAndInvoicesData)
		},
		updateEditedCourse() {
			let validInvoices = false
			let validStartMomentsInvoices = false

			if (this.$refs['invoiceItems']) {
				this.$refs['invoiceItems'].$v.$touch()

				if (!this.$refs['invoiceItems'].$v.$invalid) {
					validInvoices = true
				}
			}

			if (this.$refs['startMomentsInvoiceItems'] !== undefined && this.$refs['startMomentsInvoiceItems'].length > 0) {

				this.$refs['startMomentsInvoiceItems'].forEach((invoice) => {
					invoice.$v.$touch()

					if (!invoice.$v.$invalid) {
						validStartMomentsInvoices = true
					}
				})
			}

			if (
				(validInvoices && this.$refs['startMomentsInvoiceItems'] === undefined) ||
				(validInvoices && validStartMomentsInvoices) ||
				(validInvoices && this.$refs['startMomentsInvoiceItems'].length === 0)
			) {
				this.$emit('submitForm')
			}
		},
		setVatValue(payload) {
			this.planningAndInvoicesData[payload.key] = payload.value
			this.$emit('triggerProgressChange', this.planningAndInvoicesData)
		},
		setCountries(value) {
			this.countriesProp = value;
		}
	}
}
</script>

<!-- remove this styling on a hardening story on the main component on recess-accordion -->
<style lang="scss">
.c-recess-accordion--variant-3 .c-recess-accordion__content {
	background-color: white;
	line-height: unset;
}
</style>
